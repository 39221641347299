@tailwind base;
@tailwind components;
@tailwind utilities;
/***************
GOOGLE FONT
*****************/
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
/**************  
VARIABLES
***************/
$default: #000;

$headFont: 'Figtree', sans-serif;
$defaultFont: 'Figtree', sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headFont;
  line-height: 1.6;
  color: $default;
}
body,
p,
li,
a {
  font-family: $defaultFont;
  font-weight: 300;
  color: $default;
}
body {
  background: white;
}
#result-text {
  &.invalid {
    color: red;
    font-family: $defaultFont;
  }
}
